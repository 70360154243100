<template>
    <div class="flash-messages">
        <div
            v-for="(message, key) in messages"
            :key="key"
            :class="{
                'flash-messages__message': true,
                open: message.open,
                info: message.style === 'info',
                danger: message.style === 'danger',
                warning: message.style === 'warning',
                success: message.style === 'success',
            }"
        >
            <AtomIcon name="bell" />
            <div class="flash-messages__text">
                {{ $t(message.text) }}
            </div>
            <div class="flash-messages__ok" @click="closeMessage(message.id)">OK</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlashMessages',
    setup() {
        const messages = ref([])

        const closeMessage = (id) => {
            for (let i = 0; i < messages.value.length; ++i) {
                if (messages.value[i].id === id && messages.value[i].open) {
                    messages.value[i].open = false
                    const defaultTimeout = 500
                    setTimeout(() => {
                        if (messages.value[i].id === id) {
                            messages.value.splice(i, 1)
                        }
                    }, defaultTimeout)
                }
            }
        }

        const showMessage = (event) => {
            const detail = event.detail
            if (!detail || !detail.text) {
                return
            }

            let idCounter = 0

            const id = ++idCounter
            const defaultDuration = 3000

            const message = {
                id: id,
                text: detail.text,
                style: detail.style ?? 'info',
                duration: detail.duration ?? defaultDuration,
                open: true,
            }
            messages.value.push(message)

            setTimeout(() => {
                closeMessage(id)
            }, message.duration)
        }

        onMounted(() => {
            window.addEventListener('show-flash-message', showMessage)
        })

        onBeforeUnmount(() => {
            window.removeEventListener('show-flash-message', showMessage)
        })

        return {
            messages,
            showMessage,
            closeMessage,
        }
    },
}
</script>

<style lang="scss" scoped>
.flash-messages {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    word-wrap: normal;
    z-index: 99999999;

    @include helper-breakpoint-media-max($lg) {
        width: 100%;
    }

    &__message {
        overflow: hidden;
        position: relative;
        transition:
            height 0.3s,
            padding 0.3s;
        height: 0;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0 sp(s);

        @include helper-color(white);
        @include helper-color-bg(alert-info);
        @include flex-row();

        &.open {
            height: rem(50);
            padding: sp(xxs) sp(s);
        }

        &.warning {
            @include helper-color-bg(alert-warning);
        }

        &.success {
            @include helper-color-bg(alert-success);
        }

        &.danger {
            @include helper-color-bg(alert-danger);
        }
    }

    &__text {
        margin-left: sp(s);
    }

    &__ok {
        cursor: pointer;
        margin-left: auto;
        width: rem(30);
        margin-right: sp(xs);
        text-decoration: underline;
    }
}
</style>
